<template>
  <div class="py-0 container-fluid">
    <div class="col-lg-6 col-7">
      <h4 style="color: red">Admin mode</h4>
      <h4>{{ user }}さん の月次データ</h4>
    </div>

    <div class="row">
      <div class="row my-4">
        <div class="col-lg-12col-md-12 mb-md-0 mb-4">
          <h3>[ {{ date }} ] </h3>
          <label for="newdate">
            新規
            <Datepicker id="newdate" v-model="dtCreate" :locale="locale" :disabledTime="true" />
          </label>
          <button @click="createNode">作成</button>

          <table class="ts-table align-items-center mb-0" style="display: table !important; width: 100%">
            <thead>
              <tr>
                <th width="100px">Date</th>
                <th witdh="30px" title="土日フラグ">H</th>
                <th witdh="80px">IN</th>
                <th witdh="80px">OUT</th>
                <th witdh="80px" title="残業時間（分）">over</th>
                <th witdh="200px">Memo</th>
                <th witdh="10%">IP</th>
                <th witdh="20%" width="20%">UA</th>
                <th width="40px">m1</th>
                <th width="40px">m2</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(oo, index) in list" :key="index">
                <td :index="index">
                  {{ oo.date }}
                </td>
                <td>
                  <span @click="holiday(index)">{{ isHoliday(index) }}</span>
                </td>
                <td width="10%">
                  <input class="t-input" :id="getId('in', index)" :value="oo.in" @change="update('in', index)" />
                </td>
                <td width="10%">
                  <input class="t-input" :id="getId('out', index)" :value="oo.out" @change="update('out', index)" />
                </td>
                <td>
                  {{ oo.sub }}
                </td>

                <td style="word-wrap: break-word" width="40%">
                  <input class="m-imput" :id="getId('memo', index)" :value="oo.memo" @change="update('memo', index)" />
                </td>
                <td>
                  <span style="font-size: 0.7em">
                    {{ getIP(oo) }}
                  </span>
                </td>
                <td style="word-wrap: break-word" width="25%">
                  <span style="font-size: 0.4em">
                    {{ getUA(oo) }}
                  </span>
                </td>
                <td>
                  <button v-if="isMap(oo, 'in')" @click="callMap(oo, 'in')">
                    map
                  </button>
                </td>
                <td>
                  <button v-if="isMap(oo, 'out')" @click="callMap(oo, 'out')">
                    map
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <br /><br />
          <h4>月間サマリー</h4>
          <p>
            集計設定:<br />
            始業時刻={{ start() }}
          </p>

          <table class="statistics" :key="render">
            <tr>
              <td style="width: 120px">合計時間</td>
              <td>
                {{ getHour(this.getSumMin) }}時間 {{ getMin(this.getSumMin) }}分
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td>遅刻回数</td>
              <td>{{ this.getLateTime }} / {{ days }}</td>
            </tr>
            <tr>
              <td>遅刻時間合計</td>
              <td>
                {{ this.getLateMin }}分 ({{
                  this.getHour(this.getLateMin)
                }}時間)
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td>超過時間</td>
              <td>
                {{ this.getOverMin }}分 ({{
                  this.getHour(this.getOverMin)
                }}時間)
              </td>
            </tr>
            <tr>
              <td>不足時間</td>
              <td>{{ this.getShortMin }}分</td>
            </tr>
            <tr>
              <td>過不足計</td>
              <td>
                {{ this.getSubTotal }}分 ({{
                  this.getHour(this.getSubTotal)
                }}時間)
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <br />
    <br />
    <br />
    <a class="btn bg-gradient-success mt-1" href="#" type="button" @click="backPage">もどる</a>

    <br />

    <button class="btn btn--primary" @click="dialogState = true">
      Open Dialog
    </button>

    <br />
  </div>
  <GDialog v-model="dialogState" max-width="500">
    <div class="wrapper">
      <div class="content">
        <div class="title">New Stamp</div>
        <br />
        user :
        <p>{{ user }}</p>
        <input v-model="edDate" placefolder="2022-04-11" /><br />
        <input v-model="edIn" placefolder="10:00" /><br />
        <input v-model="edOut" placefolder="18:30" />

        <div class="actions">
          <button class="btn btn--outline-gray" @click="createStamp">
            Create
          </button>
          <button class="btn btn--outline-gray" @click="dialogState = false">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </GDialog>
</template>
<script>
//import { Magazine } from "../newsDataV2.js";
import {
  onAdminStampList,
  onUpdateMemo,
  onUpdateIn,
  onUpdateOut,
  onToggleHoliday,
  sumMonth,
  onNewStamp,
} from "../Repository";
import { GDialog } from "gitart-vue-dialog";
import { Stamp } from "../newsData";
import { addStamp } from "../Repository";
import Datepicker from "vue3-datepicker";
import "vue3-datepicker/dist/vue3-datepicker.css";
//import dateFormat, { masks } from "dateformat";
import dateFormat from "dateformat";

export default {
  name: "",
  components: { GDialog, Datepicker },
  data() {
    return {
      dtCreate: "",
      render: 0,
      edDate: "aaa",
      edIn: "bbb",
      edOut: "",
      dialogState: false,
    };
  },

  computed: {
    date() {
      return this.$store.state.selMonth;
    },
    days() {
      return this.$store.state.stamps.length;
    },

    user() {
      return this.$store.state.selUser;
    },
    list() {
      return this.$store.state.stamps;
    },
    getSumMin() {
      return this.$store.state.sumMin;
    },

    getLateMin() {
      return this.$store.state.lateMin;
    },
    getLateTime() {
      return this.$store.state.lateTime;
    },
    getOverMin() {
      return this.$store.state.overMin;
    },
    getShortMin() {
      return this.$store.state.shortMin;
    },
    getSubTotal() {
      return this.$store.state.overMin + this.$store.state.shortMin;
    },
    getSumH() {
      return this.$store.state.sumH;
    },
    getSumM() {
      return this.$store.state.sumM;
    },
  },
  updated() { },
  mounted() {
    console.log("months mounted! ----------------------------------");
    onAdminStampList(); //required  onUser & onMonth
  },
  methods: {
    start() {
      if (this.$store.state.monthObj)
        if (this.$store.state.monthObj.start)
          return this.$store.state.monthObj.start;
      if (this.$store.state.userObj) return this.$store.state.userObj.start;
      return "";
    },
    createNode() {
      console.log(this.dtCreate)
      //var cur = this.dtCreate.getFullYear() + "-" + (this.dtCreate.getMonth() + 1)
      var cur = dateFormat(this.dtCreate, "yyyy-mm");
      if (cur !== this.date) {
        alert("日付が範囲外です\n" + cur)
        return
      }
      var ymd = dateFormat(this.dtCreate, "yyyy-mm-dd");
      if (!onNewStamp(ymd))
        alert("create error.")
      else
        onAdminStampList(); //required  onUser & onMonth

    },
    isMap(obj, mode) {
      var gps;
      if (mode == "in") {
        gps = obj.envIn.gps;
      } else {
        gps = obj.envOut.gps;
      }
      if (gps) {
        return true;
      }
      return false;
    },
    isHoliday(idx) {
      if (this.$store.state.stamps[idx].holiday)
        return "休出"
      return "　"
    },

    holiday(idx) {
      console.log(this.$store.state.stamps[idx])
      //this.$store.commit("onStampHoliday", idx)
      onToggleHoliday(idx);
      sumMonth(this.$store.state.selUser);
    },
    callMap(obj, mode) {
      var url = "https://www.google.co.jp/maps/@";
      console.log(obj.envIn);
      var gps;
      if (mode == "in") {
        gps = obj.envIn.gps;
      } else {
        gps = obj.envOut.gps;
      }
      if (gps) {
        url = url + gps[0] + "," + gps[1] + ",15z";
        window.open(url);
      }
    },
    getHour(min) {
      return Math.floor(min / 60);
    },
    getMin(min) {
      return min % 60;
    },

    createStamp() {
      var st = new Stamp();
      st.mail = this.$store.state.selUser;
      st.date = this.edDate;
      st.in = this.edIn;
      st.out = this.edOut;
      st.envIn.ip = "create by Admin";
      st.envIn.ua = "create by Admin";
      addStamp(st);
      this.dialogState = false;
    },
    getId(tag, id) {
      return tag + id;
    },
    update(tag, id) {
      console.log(id);
      var val = document.getElementById(tag + id).value;
      console.log(val);
      if (tag == "memo") onUpdateMemo(parseInt(id), val);
      if (tag == "in") onUpdateIn(parseInt(id), val);
      if (tag == "out") onUpdateOut(parseInt(id), val);
    },
    updateIn(tag, id) {
      console.log(id);
      var val = document.getElementById(tag + id).value;
      console.log(val);
      onUpdateMemo(parseInt(id), val);
    },

    getIP(obj) {
      if ("envIn" in obj) return obj.envIn.ip;
      return "";
    },
    getUA(obj) {
      if ("envIn" in obj) return obj.envIn.ua;
      return "";
    },
    backPage() {
      this.$router.push("/admin/months");
    },
  },
};
</script>
<style>
td input {
  width: 100%;
}

.m-input {
  min-width: 180px;
}

.ts-table {
  border: solid 1px #ddd;
}

.ts-table tr {
  border-bottom: solid 1px #eee;
}

.ts-table td {
  border-right: solid 1px #eee;
  line-height: 16px;
}
</style>
mport
