<style>
.month-node {
  background-color: rgb(167, 217, 147);
  float: left;
  border: 2px solid rgb(159, 196, 172);
  padding: 5px 10px;
  margin: 10px 20px;
  color: #111;
  font-size: 1.2em;
  border-radius: 12%;
}

.month-node:hover {
  background-color: rgb(191, 241, 171);
  font-weight: bold;
}
</style>

<template>
  <div class="py-0 container-fluid">
    <h4 style="color: red">Admin mode</h4>
    <div class="col-lg-6 col-7">
      <h3>{{ user }}さん</h3>
    </div>

    <div class="row">
      <div class="row my-4">
        <div class="col-lg-12col-md-12 mb-md-0 mb-4">
          <h3>{{ selUser }}/過去データ一覧</h3>
          <div class="col-lg-12col-md-12 mb-md-0 mb-4">
            <tempalte v-for="(oo, index) in list" :key="oo.id">
              <div class="month-node">
                <span @click="branch(oo.date)" :index="index">
                  {{ oo.date }}
                </span>
              </div>
              <p v-if="endYear(oo.date)" style="clear: both">-</p>
            </tempalte>
          </div>
        </div>
      </div>
    </div>
    <a class="btn bg-gradient-success mt-1" href="#" type="button" @click="back"
      >もどる</a
    >
  </div>
</template>
<script>
import { onAdminMonthList } from "../Repository";

export default {
  name: "",
  //components: { NewsCardV2 },
  data() {
    return {
      render: 0,
    };
  },
  computed: {
    date() {
      return this.$store.state.dtStamp;
    },
    selUser() {
      // admin mode selected user
      return this.$store.state.selUser;
    },

    user() {
      return this.$store.state.curUser;
    },
    list() {
      return this.$store.state.months;
    },
  },
  updated() {},
  mounted() {
    console.log("months mounted! ----------------------------------");
    console.log("curUser=" + this.$store.state.curUser);
    onAdminMonthList();
  },
  methods: {
    endYear(date) {
      if (date.split("-")[1] == "12") return true;
      return false;
    },
    branch(date) {
      this.$store.commit("onSelMonth", date);
      this.$router.push("/admin/month-details");
    },
    back(date) {
      console.log(date);
      this.$router.push("/admin/members");
    },
  },
};
</script>
