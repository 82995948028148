// collection "timestamp"
export class Stamp {
  constructor() {
    this.holiday = false;
    this.mail = "";
    this.in = ""; //この記事の全文
    this.out = ""; // url reference ( comma list )
    this.memo = ""; // class NewsLine
    this.envIn = { ip: "", ua: "", gps: [] };
    this.envOut = { ip: "", ua: "", gps: [] };
    var date = new Date(); // Or the date you'd like converted.
    var t = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    ).toISOString();
    var dt = t.split("T");
    this.date = dt[0];
  }
  static create(obj) {
    var ret = new Stamp();
    ret.holiday = obj.holiday;
    ret.in = obj.in;
    ret.date = obj.date;
    ret.out = obj.out;
    ret.memo = obj.memo;
    ret.mail = obj.mail;
    ret.envIn = obj.envIn;
    ret.envOut = obj.envOut;
    return ret;
  }
}

// collection "months"
export class Month {
  constructor() {
    this.user = "";
    var date = new Date(); // Or the date you'd like converted.
    var t = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    ).toISOString();
    var dt = t.split("-");
    this.date = dt[0] + "-" + dt[1]; //yyyy-mm
  }
  static create(obj) {
    var ret = new Month();
    ret.user = obj.user;
    ret.date = obj.date;
    return ret;
  }
}

// Collection 'user"
export class User {
  constructor() {
    this.id = "";
    this.name = "";
    this.mail = "";
    this.role = "";
    this.start = "10:00"; // coretime start
    this.start = "18:00"; // coretime end
  }
  static create(obj) {
    var uu = new User();
    uu.id = obj.id;
    uu.name = obj.name;
    uu.mail = obj.mail;
    uu.role = obj.role;
    uu.start = obj.start;
    uu.end = obj.end;
    return uu;
  }
}
