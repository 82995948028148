<style>
.user-node {
  background-color: rgb(190, 229, 241);
  border: 2px solid rgb(159, 196, 172);
  padding: 5px 10px;
  margin: 10px 20px;
  color: #111;
  font-size: 1.2em;
}

.month-node:hover {
  background-color: rgb(191, 241, 171);
  font-weight: bold;
}
</style>

<template>
  <div class="py-0 container-fluid">
    <h4 style="color: red">Admin mode</h4>
    <div class="col-lg-6 col-7">
      <h3>{{ user }}さん</h3>
    </div>

    <div class="row">
      <div class="row my-4">
        <div class="col-lg-12col-md-12 mb-md-0 mb-4">
          <h3>Admin メンバー一覧</h3>
          <div class="col-lg-12col-md-12 mb-md-0 mb-4">
            <tempalte v-for="(oo, index) in list" :key="index">
              <div class="user-node">
                <span @click="branch(oo.mail)">
                  {{ oo.mail }}
                </span>
              </div>
            </tempalte>
          </div>
        </div>
      </div>
    </div>
    <a class="btn bg-gradient-success mt-1" href="#" type="button" @click="back"
      >もどる</a
    >
  </div>
</template>
<script>
import { onUserList } from "../Repository";

export default {
  name: "",
  //components: { NewsCardV2 },
  data() {
    return {
      render: 0,
    };
  },
  computed: {
    date() {
      return this.$store.state.dtStamp;
    },
    user() {
      return this.$store.state.curUser;
    },
    list() {
      return this.$store.state.users;
    },
  },
  updated() {},
  mounted() {
    onUserList();
  },
  methods: {
    endYear(date) {
      if (date.split("-")[1] == "12") return true;
      return false;
    },
    branch(mail) {
      this.$store.commit("onSelUser", mail);
      this.$router.push("/admin/months");
    },
    back(date) {
      console.log(date);
      this.$router.push("/stamp");
    },
  },
};
</script>
