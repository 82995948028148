<template>
  <div class="py-0 container-fluid">
    <div class="col-lg-6 col-7">
      <h2>タイムレコーダー v2</h2>
      <a @click="logout">logout</a>
      <h4>ユーザー：{{ loginUser.name }}さん</h4>

      <h3 v-if="mdIN">入室モード</h3>
      <h3 v-if="mdOUT">退室モード</h3>
      <h3 v-if="mdEND">お疲れ様でした</h3>
      <p class="text-sm mb-0"></p>
    </div>
    <i v-if="clicked" class="time fa fa-check text-info" aria-hidden="true"></i>
    <span class="done">{{ done }}</span>
    <button v-if="btn" class="block btnStamp" :class="btnClass" @click="press">
      <p class="time font-weight-bold ms-1">{{ now }}</p>
      <span v-if="mdIN">入室</span>
      <span v-if="mdOUT">退室</span>
    </button>
    <a
      class="btn bg-gradient-success mt-1"
      href="#"
      type="button"
      @click="monthDetails"
      >今月のタイムカード</a
    >
    <br /><br />
    <a
      v-if="isAdmin"
      class="btn bg-gradient-success mt-1"
      href="#"
      type="button"
      @click="toAdmin"
      >admin</a
    >
    <br /><br />
  </div>
</template>
<style>
.time {
  font-size: 1em;
  color: rgb(160, 24, 90);
  text-align: text-center;
}

.btnIn:hover {
  background: rgb(255, 235, 122);
  color: rgb(255, 10, 10);
}

.btnStamp {
  border-radius: 40px 40px;
  padding: 1em 1em;
  text-decoration: none;
  font-size: 2.5em;
  font-weight: bold;
  font-family: sans-serif;
}

.btnIn:hover {
  background: rgb(255, 235, 122);
  color: rgb(255, 10, 10);
}

.btnIn {
  background: gold;
  border: rgb(255, 255, 114) 8px solid;
  color: black;
}

.btnOut:hover {
  background: rgb(33, 86, 157);
  border: rgb(126, 179, 237) 8px solid;
  color: rgb(255, 255, 255);
}

.btnOut {
  background: rgb(21, 48, 84);
  border: rgb(114, 182, 255) 8px solid;
  color: rgb(255, 255, 255);
}

.block {
  display: block;
  margin: 0 auto;
  margin-top: 50px;
}
</style>
<script>
import { onAuthLogout, onCheckStamp, onStampExe } from "../Repository";

export default {
  name: "",
  //components: { NewsCardV2 },
  data() {
    return {
      btnEnable: true,
      done: "",
      render: 0,
      now: "",
      clicked: false,
    };
  },
  computed: {
    btnClass() {
      if ("IN" == this.$store.state.stampMode) return { btnIn: true };
      return { btnOut: true };
    },
    isAdmin() {
      return this.$store.state.loginUser.role == "admin";
    },
    loginUser() {
      return this.$store.state.loginUser;
    },
    btn() {
      if ("END" == this.$store.state.stampMode) return false;
      return this.btnEnable;
    },
    user() {
      return this.$store.state.mail;
    },
    list() {
      return this.$store.state.months;
    },
    mode() {
      return this.$store.state.stampMode;
    },
    mdIN() {
      console.log(this.$store.state.stampMode);
      return "IN" == this.$store.state.stampMode;
    },
    mdOUT() {
      return "OUT" == this.$store.state.stampMode;
    },
    mdEND() {
      return "END" == this.$store.state.stampMode;
    },
  },
  updated() {},
  mounted() {
    console.log("months mounted! ----------------------------------");
    //this.$store.commit("onUser", this.$store.state.mail); // yyyy-mm
    onCheckStamp();
    this.dtNow();
  },
  methods: {
    press() {
      this.clicked = true;
      this.btnEnable = false;
      this.done = "押印しました！";
      console.log(this.mode);
      if (this.mode == "IN" || this.mode == "OUT") {
        onStampExe();
        this.monthDetails();
      }
    },
    dtNow() {
      var date = new Date();
      this.now =
        date.getHours() +
        "時 " +
        date.getMinutes() +
        "分 " +
        date.getSeconds() +
        "秒";
      setTimeout(() => {
        this.dtNow();
      }, 200);
    },
    monthDetails() {
      var date = new Date(); // Or the date you'd like converted.
      var t = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        .toISOString()
        .split("-");
      //this.$store.commit("onUser", this.$store.state.mail); // yyyy-mm
      this.$store.commit("onMonth", t[0] + "-" + t[1]); // yyyy-mm
      this.$router.push("month-details");
    },
    logout() {
      onAuthLogout();
      this.$router.push("/");
    },
    branch(date) {
      console.log(date);
      this.$store.commit("onMonth", date);
      this.$router.push("month-details");
    },
    toAdmin() {
      this.$router.push("/admin/members");
    },
  },
};
</script>
