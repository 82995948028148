import { createRouter, createWebHistory } from "vue-router";
import AdminMembers from "@/views/AdmMembers.vue";
import AdminMonths from "@/views/AdmMonths.vue";
import AdminMonthDetails from "@/views/AdminMonthDetails.vue";
import Months from "@/views/Months.vue";
import Stamp from "@/views/Stamp.vue";
import MonthDetails from "@/views/MonthDetails.vue";
import Profile from "@/views/Profile.vue";
import SignIn from "@/views/SignIn.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/sign-in",
  },
  // v2
  {
    path: "/stamp",
    name: "Stamp",
    component: Stamp,
  },

  {
    path: "/months",
    name: "Months",
    component: Months,
  },
  {
    path: "/month-details",
    name: "MonthDetails",
    component: MonthDetails,
  },

  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/sign-in",
    name: "Sign In",
    component: SignIn,
  },
  // admin
  {
    path: "/admin/members",
    name: "admin-members",
    component: AdminMembers,
  },
  {
    path: "/admin/months",
    name: "admin-months",
    component: AdminMonths,
  },
  {
    path: "/admin/month-details",
    name: "admin-month-details",
    component: AdminMonthDetails,
  },
];

const appRouter = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

import store from "../store";

appRouter.beforeEach((to, from, next) => {
  // console.log("router before each---->" + to.path);
  var token = store.state.token;
  // var token = "";
  //console.log(token);

  if (token == "") {
    console.log("no login session");
    if (to.path != "/sign-in") {
      appRouter.push("/sign-in");
    }

    //this.$store.dispatch("checkLogin");
  }
  next();
});

export default appRouter;
