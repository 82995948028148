import { createStore } from "vuex";

export default createStore({
  state: {
    // app auth
    user: null, //user object
    mail: "",
    token: "",
    isAuth: false,
    email: "",
    viewMode: "", // admin or not
    curUser: "??", // mail
    dtStamp: "", // yyyy-mm
    months: [],
    monthObj: null,
    stamps: [],
    users: [],
    ip: "",
    ua: "",
    loginUser: {},
    gps: [34.845594, 136.538952, 1000], //suzuka circuit [lat,long, accuracy]
    todayStamp: null,
    stampMode: "",
    // admin
    selMonth: "",
    selUser: "",
    // sum
    sumH: 0,
    sumM: 0,
    sumMin: 0,
    lateTime: 0,
    lateMin: 0,
    overMin: 0,
    shortMin: 0,
  },
  getters: {
    getUser: (state) => () => {
      return state.mail;
    },
    getArticle: (state, idx) => () => {
      //console.log(state.newsAry);
      //console.log(idx);
      if (state.newsAry.length < idx) return state.newsAry[idx];

      console.log("Article index overflow");
      return null;
    },
  },
  actions: {
    checkLogin({ commit }) {
      console.log("checkLogin");
      commit("checkSessionToken");
    },
    auth({ commit }, user) {
      console.log(user);
      commit("onAuthChanged", user.mail, user.token);
    },
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    },
  },
  mutations: {
    //env
    onIP(state, ip) {
      state.ip = ip;
    },
    onUA(state, ua) {
      state.ua = ua;
    },
    // user
    onUserLoad(state, obj) {
      state.users.push(obj);
    },
    onUserReset(state) {
      state.users = [];
    },
    onLoginUser(state, obj) {
      state.loginUser = obj;
    },

    // stamp sum
    onLateTime(state, mm) {
      state.lateTime = mm;
    },
    onLateMin(state, mm) {
      state.lateMin = mm;
    },
    onOverMin(state, mm) {
      state.overMin = mm;
    },
    onShortMin(state, mm) {
      state.shortMin = mm;
    },
    onSumMin(state, mm) {
      state.sumMin = mm;
    },

    onGPS(state, obj) {
      console.log(obj);
      state.gps = obj;
    },

    onSum(state, val) {
      console.log(val);
      state.sumH = val.hh;
      state.sumM = val.mm;
    },
    // stamp
    onTodayStamp(state, obj) {
      state.todayStamp = obj;
    },
    onStampMode(state, mode) {
      state.stampMode = mode;
    },
    onMonthReset(state) {
      state.months = [];
    },
    onMonthLoad(state, obj) {
      state.months.push(obj);
    },

    onStampReset(state) {
      state.stamps = [];
    },
    onStampLoad(state, obj) {
      state.stamps.push(obj);
    },

    onStampHoliday(state, idx) {
      if (state.stamps[idx].holiday) {
        state.stamps[idx].holiday = false;  //!state.stamps[idx].holiday;
      }
      else
        state.stamps[idx].holiday = true;

    },
    onMonthObj(state, obj) {
      //yyyy-mm
      state.monthObj = obj;
    },

    onMonth(state, dt) {
      //yyyy-mm
      state.dtStamp = dt;
    },
    onUserObj(state, user) {
      //yyyy-mm
      console.log("onUserObj=>" + user);
      state.user = user;
    },

    onUser(state, user) {
      //yyyy-mm
      console.log("onUser=>" + user);
      state.curUser = user;
    },
    // for admin
    onSelUser(state, user) {
      // admin mode user select
      //yyyy-mm
      console.log("onSelUser=>" + user);
      state.selUser = user;
    },
    onSelMonth(state, month) {
      // admin mode user select
      //yyyy-mm
      console.log("onSelmonth=>" + month);
      state.selMonth = month;
    },

    checkSessionToken(state) {
      console.log(state.token);
      console.log("checkSessionToken");
    },
    onAuthChanged(state, mail, token) {
      state.curUser = mail;
      state.mail = mail;
      state.token = token;
      state.isAuth = true;
      //onAuthByEmail( mail, )
    },
    onLogout(state) {
      state.mail = "";
      state.token = "";
      state.isAuth = false;
      console.log("onAuthLogout");
    },

    // UI
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");
      if (sidenav_show.classList.contains("g-sidenav-hidden")) {
        sidenav_show.classList.remove("g-sidenav-hidden");
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    sidebarType(state, payload) {
      state.isTransparent = payload;
    },
    cardBackground(state, payload) {
      state.color = payload;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    toggleEveryDisplay(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    toggleHideConfig(state) {
      state.hideConfigButton = !state.hideConfigButton;
    },
  },
});
