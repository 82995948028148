//import firebase from "firebase/compat/app";
import { onBeforeUnmount } from "vue";
import { initializeApp } from "firebase/app";

import {
  getAuth,
  setPersistence,
  browserSessionPersistence,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";

import {
  doc,
  getFirestore,
  collection,
  addDoc,
  getDocs,
  //  limit,
  orderBy,
  where,
  query,
  updateDoc,
  //setDoc,
  //snapshotEqual,
} from "firebase/firestore";

import "firebase/compat/auth";
import store from "./store";
import appRouter from "./router";
import { User, Month, Stamp } from "./newsData";
//import { FontAwesomeLayersText } from "@fortawesome/vue-fontawesome";

console.log(
  " firebase initialization start----------------------------------------"
);
/*
// init v1
const firebaseConfig = {
  apiKey: "AIzaSyAw4hSW7uIejeo0vSkf5q77yiC4KRGlJ0w",
  authDomain: "timestamp-d1ffe.firebaseapp.com",
  projectId: "timestamp-d1ffe",
  storageBucket: "timestamp-d1ffe.appspot.com",
  messagingSenderId: "709555879570",
  appId: "1:709555879570:web:61d38dc6b645aee37af04b",
};
*/
// database v2
const firebaseConfig = {
  apiKey: "AIzaSyDJZwDc5uq_RI0dAzjjq8od2jSEm2Gktho",
  authDomain: "timestamp-v2.firebaseapp.com",
  projectId: "timestamp-v2",
  storageBucket: "timestamp-v2.appspot.com",
  messagingSenderId: "557520168004",
  appId: "1:557520168004:web:35ca701d5c77e0201b0811",
};

// setup firebase
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const coTS = "timestamp";
const coMon = "months";
const coUser = "tsusers";
const tsDB = collection(db, coTS);
const monDB = collection(db, coMon);
const userDB = collection(db, coUser);

console.log("!!! add auth listener --------------------");
export const authListener = onAuthStateChanged(auth, function (user) {
  console.log(user);
});
onBeforeUnmount(() => {
  // clear up listener
  authListener();
});

// util ----------------------------
function getUserIP(onNewIP) {
  var ipUrl = "https://api.ipify.org/?format=json";
  fetch(ipUrl)
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      onNewIP(data.ip);
    });
}
export function detectMob() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}

// AUTH ---------------------------------------------------------------------------------------------------
export function authInit() {
  setPersistence(auth, browserSessionPersistence);
}
export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

//
function onGpsError(error) {
  console.log(error);
  if (gpsCnt > 5) {
    alert(
      "エラーが５回を超えました。管理者にご連絡の上、ブラウザの設定を確認してからリロード後にログインをしてください."
    );
    appRouter.replace("/stamp");
    return;
  }
  alert(
    error.message +
    "\n" +
    "位置情報を取得できません。\nブラウザの設定を位置情報の取得が可能にしてください。"
  );
  approvalGPS();
  return;
}

//----------------------------------------------
function onGPS(position) {
  console.log(position);

  var geo_text = "緯度:" + position.coords.latitude + "\n";
  geo_text += "経度:" + position.coords.longitude + "\n";
  geo_text += "高度:" + position.coords.altitude + "\n";
  geo_text += "位置精度:" + position.coords.accuracy + "\n";
  geo_text += "高度精度:" + position.coords.altitudeAccuracy + "\n";
  geo_text += "移動方向:" + position.coords.heading + "\n";
  geo_text += "速度:" + position.coords.speed + "\n";

  var date = new Date(position.timestamp);

  geo_text += "取得時刻:" + date.toLocaleString() + "\n";
  console.log(geo_text);
  store.commit("onGPS", [
    position.coords.latitude,
    position.coords.longitude,
    position.coords.accuracy,
  ]);
  appRouter.replace("/stamp");
}

// -------------------------------------------
var gpsCnt = 0;
function approvalGPS() {
  /*
  alert(
    "位置情報を取得します。\nブラウザの設定を位置情報の取得が可能にしてください。\n ( chrome://settings/content?search=位置情報 )"
  ); */
  navigator.geolocation.getCurrentPosition(onGPS, onGpsError);
}

// -------------------------------------------
export function onAuthByEmail(mail, passwd) {
  if (onAuthTest()) {
    onAuthLogout();
  }
  console.log("onAuthChanged");
  signInWithEmailAndPassword(auth, mail, passwd)
    .then(
      // 成功時の処理
      (data) => {
        console.log("login succeeded.");
        store.commit("onAuthChanged", data.user.email, data.user.accessToken);
        onUserChecknCreate(data.user.email);
        onUserList();
        getUserIP(function (ip) {
          store.commit("onIP", ip);
          console.log(ip);
          if (ip == "") alert("IPが取得が取得できませんでした。");
        });
        approvalGPS();
      }
    )
    .catch(
      // エラー時の処理
      (error) => {
        console.log(error);
        alert("メールアドレスとpasswordの組み合わせが違うようです。");
        store.commit("onLogout");
      }
    );
}
//--------------------------
export function onAuthLogout() {
  auth.signOut().then(() => {
    store.commit("onLogout");
    appRouter.replace("login");
  });
}
//--------------------------
export function onAuthTest() {
  var user = auth.currentUser;
  console.log(user);
  if (!user) return false;
  return user.accessToken != "";
}

// Admin  ---------------------------------------------------------------------------------------------------
export async function onMemberList() {
  if (!onAuthTest()) {
    alert("please login");
    appRouter.push("/sign-in");
    return false, "auth";
  }
  store.commit("onUserReset");
  console.log("curUser=" + store.state.curUser);
  const qq = query(userDB, orderBy("name"));
  const querySnapshot = await getDocs(qq);
  querySnapshot.forEach((_doc) => {
    var zobj = _doc.data();
    console.log(zobj);
    zobj.id = _doc.id;
    var obj = User.create(zobj);
    store.commit("onUserLoad", obj);
  });
}

async function monthListBody(user) {
  store.commit("onMonthReset");
  const qq = query(monDB, where("user", "==", user), orderBy("date", "desc"));
  const querySnapshot = await getDocs(qq);
  querySnapshot.forEach((_doc) => {
    var zobj = _doc.data();
    zobj.id = _doc.id;
    var obj = Month.create(zobj);
    store.commit("onMonthLoad", obj);
  });
}

export async function onAdminMonthList() {
  if (!onAuthTest()) {
    alert("please login");
    appRouter.push("/sign-in");
    return false, "auth";
  }
  monthListBody(store.state.selUser);
}

export async function onAdminStampList() {
  if (!onAuthTest()) {
    alert("please login");
    appRouter.push("/sign-in");
    return false, "auth";
  }
  onGetMonth(store.state.selUser, store.state.selMonth).then((obj) => {
    store.commit("onMonthObj", obj);
    stampListBody(store.state.selUser, store.state.selMonth + "-01");
  });
}

// User ------------------------------------------------------------------------
export async function onUserList() {
  if (!onAuthTest()) {
    alert("please login");
    appRouter.push("/sign-in");
    return false, "auth";
  }
  store.commit("onUserReset");
  const qq = query(userDB, orderBy("name"));
  const querySnapshot = await getDocs(qq);
  querySnapshot.forEach((_doc) => {
    var zobj = _doc.data();
    console.log(zobj);
    zobj.id = _doc.id;
    var obj = User.create(zobj);
    store.commit("onUserLoad", obj);
  });
}
export async function onUserExist(mail) {
  const qq = query(userDB, where("mail", "==", mail));
  const querySnapshot = await getDocs(qq);
  querySnapshot.forEach((_doc) => {
    var obj = _doc.data();
    store.commit("onLoginUser", obj);
  });
  if (querySnapshot.size > 0) return true;
  return false;
}
async function createUser(mail) {
  console.log("create user");
  var obj = new User();
  obj.mail = mail;
  await addDoc(userDB, JSON.parse(JSON.stringify(obj)));
  console.log("user created.");
}
//
export async function onUserChecknCreate(mail) {
  console.log("user check " + mail);
  onUserExist(mail).then((ret) => {
    console.log(ret);
    if (ret) {
      return;
    }
    createUser(mail);
  });
}
export async function onUserLoad(mail) {
  const qq = query(userDB, where("mail", "==", mail));
  const qss = await getDocs(qq);
  for (var ii in qss.docs) {
    var obj = qss.docs[ii].data();
    break;
  }
  return obj;
}

// MONTH ------------------------------------------------------------------------
export async function onMonthList() {
  if (!onAuthTest()) {
    alert("please login");
    appRouter.push("/sign-in");
    return false, "auth";
  }
  monthListBody(store.state.curUser);
}

async function createMonth() {
  var obj = new Month();
  obj.user = store.state.mail;
  var user = getUserByMail(obj.user);
  obj.start = user.start;
  await addDoc(monDB, JSON.parse(JSON.stringify(obj)));
}

export async function onMonthExist() {
  var obj = new Month();
  var user = store.state.mail;
  const qq = query(
    monDB,
    where("user", "==", user),
    where("date", "==", obj.date)
  );
  const querySnapshot = await getDocs(qq);

  if (querySnapshot.size > 0) {
    return true;
  }
  return false;
}

//
async function checkMonth() {
  onMonthExist().then((ret) => {
    if (ret) {
      return;
    }
    createMonth();
  });
}

async function onGetMonth(mail, date) {
  const qq = query(monDB, where("user", "==", mail), where("date", "==", date));
  const qss = await getDocs(qq);
  for (var i in qss.docs) {
    console.log(qss.docs[i]);
    var month = qss.docs[i].data();
    return month;
  }
}

// Stamp ------------------------------------------------------------------------

export async function onUpdateMemo(id, val) {
  if (!onAuthTest()) {
    alert("please login");
    appRouter.push("/sign-in");
    return false, "auth";
  }
  // 'id' is state.stamps[] index.
  console.log(store.state.stamps[id].memo);
  store.state.stamps[id].memo = val;
  var obj = store.state.stamps[id];
  const qq = query(
    tsDB,
    where("mail", "==", obj.mail),
    where("date", "==", obj.date)
  );
  const qss = await getDocs(qq);
  for (var i in qss.docs) {
    console.log(qss.docs[i]);
    var uo = qss.docs[i].data();
    uo.memo = val;
    const dref = doc(db, coTS, qss.docs[i].id);
    updateDoc(dref, uo);
    break;
  }
}

export async function onToggleHoliday(id) {
  if (!onAuthTest()) {
    alert("please login");
    appRouter.push("/sign-in");
    return false, "auth";
  }

  store.commit("onStampHoliday", id)
  var h = store.state.stamps[id].holiday;
  var obj = store.state.stamps[id];
  console.log(obj)
  console.log(h)
  const qq = query(
    tsDB,
    where("mail", "==", obj.mail),
    where("date", "==", obj.date)
  );

  const qss = await getDocs(qq);
  for (var i in qss.docs) {
    console.log(qss.docs[i]);
    var uo = qss.docs[i].data();
    uo.holiday = h;
    console.log(uo)
    const dref = doc(db, coTS, qss.docs[i].id);
    console.log(dref)
    updateDoc(dref, uo);
    break;
  }
}

export async function onUpdateIn(id, val) {
  if (!onAuthTest()) {
    alert("please login");
    appRouter.push("/sign-in");
    return false, "auth";
  }
  // 'id' is state.stamps[] index.
  store.state.stamps[id].in = val;
  var obj = store.state.stamps[id];
  const qq = query(
    tsDB,
    where("mail", "==", obj.mail),
    where("date", "==", obj.date)
  );
  const qss = await getDocs(qq);
  for (var i in qss.docs) {
    console.log(qss.docs[i]);
    var uo = qss.docs[i].data();
    uo.in = val;
    uo.envIn.gps = store.state.gps;
    const dref = doc(db, coTS, qss.docs[i].id);
    updateDoc(dref, uo);
    break;
  }
}

export async function onUpdateOut(id, val) {
  if (!onAuthTest()) {
    alert("please login");
    appRouter.push("/sign-in");
    return false, "auth";
  }
  // 'id' is state.stamps[] index.
  store.state.stamps[id].out = val;
  var obj = store.state.stamps[id];
  const qq = query(
    tsDB,
    where("mail", "==", obj.mail),
    where("date", "==", obj.date)
  );
  const qss = await getDocs(qq);
  for (var i in qss.docs) {
    console.log(qss.docs[i]);
    var uo = qss.docs[i].data();
    uo.out = val;
    obj.envOut.ip = store.state.ip;
    obj.envOut.ua = window.navigator.userAgent;
    uo.envOut.gps = store.state.gps;
    console.log(uo);
    const dref = doc(db, coTS, qss.docs[i].id);
    updateDoc(dref, uo);
    break;
  }
}

async function stampListBody(user, date) {
  store.commit("onStampReset");
  var ds = date.split("-");
  console.log(ds);
  var yy = parseInt(ds[0]);
  var mm = parseInt(ds[1]);
  if (mm == 12) {
    yy += 1;
    mm = 1;
  } else {
    mm += 1;
  }
  var month = mm.toString().padStart(2, "0");
  var endM = yy + "-" + month + "-01";
  console.log("list " + date + " to " + endM);
  const qq = query(
    tsDB,
    where("mail", "==", user),
    where("date", ">=", date),
    where("date", "<", endM),
    orderBy("date")
  );
  const querySnapshot = await getDocs(qq);
  querySnapshot.forEach((_doc) => {
    var zobj = _doc.data();
    zobj.id = _doc.id;
    var obj = Stamp.create(zobj);
    console.log(zobj);
    store.commit("onStampLoad", obj);
  });
  sumMonth(user);
}

//------------ sum monthly hours
function getHM(str) {
  let s = str.split(":");
  return [parseInt(s[0]), parseInt(s[1])];
}
function getMin(_in, _out) {
  let ss = getHM(_in);
  let ee = getHM(_out);
  if (isNaN(ee[0]) | isNaN(ss[0])) return NaN;

  let mm = 0;
  mm = ee[1] - ss[1];
  mm += (ee[0] - ss[0]) * 60;
  return mm;
}

function getUserByMail(mail) {
  for (var i in store.state.users) {
    if (store.state.users[i].mail == mail) return store.state.users[i];
  }
  return null;
}

function sumMonth(userMail) {
  let lateTime = 0;
  let lateMin = 0;
  let overMin = 0;
  let shortMin = 0;
  let total = 0;
  var user = getUserByMail(userMail);
  var work_start = user.start;
  if (store.state.monthObj) work_start = store.state.monthObj.start;
  var hm = getHM(work_start);

  var start = hm[0] * 60 + hm[1];
  for (var tt in store.state.stamps) {
    var tm = store.state.stamps[tt];
    //
    if (tm.out != "") {
      // working min
      var min = getMin(tm.in, tm.out);
      if (isNaN(min)) continue;
      total += min;

      if (tm.holiday) {
        overMin += min;
        store.state.stamps[tt].sub = min;
      }
      else {
        // over time
        var sub = min - 9 * 60;
        store.state.stamps[tt].sub = sub;
        if (sub > 0) overMin += sub;
        else shortMin += sub;
        // late
        var inHM = getHM(tm.in);
        var inM = inHM[0] * 60 + inHM[1];
        if (inM > start) {
          lateTime++;
          lateMin += inM - start;
        }
      }
    }
  }
  store.commit("onLateTime", lateTime);
  store.commit("onLateMin", lateMin);
  store.commit("onOverMin", overMin);
  store.commit("onShortMin", shortMin);
  store.commit("onSumMin", total);
  let mm = total % 60;
  let hh = Math.floor(total / 60);
  store.commit("onSum", { hh, mm });
}

export async function onStampList() {
  if (!onAuthTest()) {
    alert("please login");
    appRouter.push("/sign-in");
    return false, "auth";
  }

  onGetMonth(store.state.curUser, store.state.dtStamp).then((obj) => {
    store.commit("onMonthObj", obj);
    stampListBody(store.state.curUser, store.state.dtStamp + "-01");
  });
}


export async function onNewStamp(YYYY_MM_DD) {
  var user = store.state.selUser;
  const qq = query(tsDB, where("mail", "==", user), where("date", "==", YYYY_MM_DD));
  const querySnapshot = await getDocs(qq);
  if (querySnapshot.size > 0) {  //already exist
    return false;
  }

  try {
    var obj = new Stamp();
    //obj.mail = store.state.mail;
    obj.mail = store.state.selUser;
    obj.date = YYYY_MM_DD;
    obj.in = "10:00"
    obj.envIn.ip = store.state.ip;
    obj.envIn.ua = window.navigator.userAgent;
    obj.envIn.gps = store.state.gps;
    console.log(window.navigator.userAgent);
    console.log(obj);
    await addDoc(tsDB, JSON.parse(JSON.stringify(obj)));
  } catch (e) {
    alert("add data error");
    console.error("Error adding stamp : ", e);
    return false
  }
  return true;
}


export async function onStampExist() {
  var user = store.state.mail;
  var dt = getStrLocalNow().split("T");
  const qq = query(tsDB, where("mail", "==", user), where("date", "==", dt[0]));
  const querySnapshot = await getDocs(qq);
  //console.log(querySnapshot.size);
  if (querySnapshot.size > 0) {
    return true;
  }
  return false;
}

export async function addStamp(obj) {
  console.log(obj);
  if (obj.date == "") {
    alert("データが不正です\n" + JSON.stringify(obj));
    return;
  }
  if (obj.user == "") {
    alert("データが不正です\n" + JSON.stringify(obj));
    return;
  }
  await addDoc(tsDB, JSON.parse(JSON.stringify(obj)));
}

async function stampCreate() {
  /* 
  if (detectMob()) {
    alert("スマホからの打刻はできません");
    return;
  }
*/
  try {
    var dt = getStrLocalNow().split("T");
    var tt = dt[1].split(":");
    var obj = new Stamp();
    obj.mail = store.state.mail;
    obj.date = dt[0];
    obj.in = tt[0] + ":" + tt[1];
    obj.envIn.ip = store.state.ip;
    obj.envIn.ua = window.navigator.userAgent;
    obj.envIn.gps = store.state.gps;
    console.log(window.navigator.userAgent);
    console.log(obj);
    await addDoc(tsDB, JSON.parse(JSON.stringify(obj)));
  } catch (e) {
    alert("add data error");
    console.error("Error adding stamp : ", e);
    return { res: false, msg: "add error", obj: null };
  }
  store.commit("onTodayStamp", obj);
  return { res: true, msg: "add ok", obj: obj };
}

export async function onStampCreate() {
  console.log("create stamp");
  onStampExist().then((ret) => {
    console.log(ret);
    if (ret) {
      return;
    }
    checkMonth();
    stampCreate();
  });
}

function getStrLocalNow() {
  var date = new Date(); // Or the date you'd like converted.
  var t = new Date(
    date.getTime() - date.getTimezoneOffset() * 60000
  ).toISOString();
  return t;
}
export async function onCheckStamp() {
  if (!onAuthTest()) {
    alert("please login");
    appRouter.push("/sign-in");
    return false, "auth";
  }
  var user = store.state.mail;
  var dt = getStrLocalNow().split("T");
  var mode = ["IN", "OUT", "END", "NONE"];
  var idx = 0;
  const qq = query(tsDB, where("mail", "==", user), where("date", "==", dt[0]));
  const querySnapshot = await getDocs(qq);
  var cur;
  querySnapshot.forEach((_doc) => {
    var obj = _doc.data();
    cur = Stamp.create(obj);
    cur.id = _doc.id;
    if (obj.in != "") idx += 1;
    //if (obj.out != "") idx += 1;   //OUTを何度でも押せるようにした
  });
  store.commit("onTodayStamp", cur);
  store.commit("onStampMode", mode[idx]);
}

export async function getStampID(mail, date) {
  const qq = query(tsDB, where("mail", "==", mail), where("date", "==", date));
  const querySnapshot = await getDocs(qq);
  querySnapshot.forEach((_doc) => {
    return _doc.id;
  });
}

export async function updateStamp(mail, date, data) {
  const qq = query(tsDB, where("mail", "==", mail), where("date", "==", date));
  const querySnapshot = await getDocs(qq);
  querySnapshot.forEach((_doc) => {
    console.log(_doc.id);
    console.log(data);
    console.log(_doc.id);
    updateDoc(doc(tsDB, _doc.id), data);
    return;
  });
}

export async function onStampExe() {
  if (!onAuthTest()) {
    alert("please login");
    appRouter.push("/sign-in");
    return false, "auth";
  }
  var dt = getStrLocalNow().split("T");
  var tt = dt[1].split(":");
  tt = tt[0] + ":" + tt[1];
  // IN
  if (store.state.stampMode == "IN") {
    //var st = new Stamp(); // create with date
    onStampCreate();
  }
  // OUT
  if (store.state.stampMode == "OUT") {
    store.state.todayStamp.out = tt;
    var env = {
      ip: store.state.ip,
      ua: window.navigator.userAgent,
      gps: store.state.gps,
    };
    updateStamp(store.state.mail, dt[0], { out: tt, envOut: env });
  }
}
