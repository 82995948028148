<template>
  <div class="py-0 container-fluid">
    <div class="col-lg-6 col-7">
      <h4>{{ user }}さん の月次データ</h4>
    </div>

    <div class="row">
      <div class="row my-4">
        <div class="col-lg-12col-md-12 mb-md-0 mb-4">
          <h3>[ {{ date }} ]</h3>
          <table class="ts-table align-items-center mb-0" style="display: table !important; width: 100%">
            <thead>
              <tr>
                <th width="100px">Date</th>
                <th witdh="30px" title="土日フラグ">H</th>
                <th witdh="80px">IN</th>
                <th witdh="80px">OUT</th>
                <th witdh="80px" title="残業（分）">over</th>
                <th witdh="200px">Memo</th>
                <th witdh="10%">IP</th>
                <th witdh="20%" width="20%">UA</th>
                <th width="40px">m1</th>
                <th width="40px">m2</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(oo, index) in list" :key="index">
                <td :index="index">
                  {{ oo.date }}
                </td>
                <td>
                  <span>{{ isHoliday(index) }}</span>
                </td>

                <td>
                  <span style="font-size: 1.2em">
                    {{ oo.in }}
                  </span>
                </td>
                <td>
                  <span style="font-size: 1.2em">
                    {{ oo.out }}
                  </span>
                </td>
                <td>
                  {{ oo.sub }}
                </td>
                <td style="word-wrap: break-word" width="40%">
                  <input class="input" :id="getMemoId(index)" :value="oo.memo" @change="updateMemo(index)" />
                </td>
                <td>
                  <span style="font-size: 0.7em">
                    {{ getIP(oo) }}
                  </span>
                </td>
                <td style="word-wrap: break-word" width="25%">
                  <span style="font-size: 0.4em">
                    {{ getUA(oo) }}
                  </span>
                </td>
                <td>
                  <button v-if="isMap(oo, 'in')" @click="callMap(oo, 'in')">
                    map
                  </button>
                </td>
                <td>
                  <button v-if="isMap(oo, 'out')" @click="callMap(oo, 'out')">
                    map
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <br /><br />
          <h4>月間サマリー</h4>
          <p>
            集計設定:<br />
            始業時刻={{ start() }}
          </p>

          <table class="statistics" :key="render">
            <tr>
              <td style="width: 140px">合計時間</td>
              <td style="width: 240px">
                {{ getHour(this.getSumMin) }}時間
                {{ getHour(this.getSumMin) }}分
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td>遅刻回数</td>
              <td>{{ this.getLateTime }} / {{ days }}</td>
            </tr>
            <tr>
              <td>遅刻時間合計</td>
              <td>
                {{ this.getLateMin }}分 ({{
                  this.getHour(this.getLateMin)
                }}時間)
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>超過時間</td>
              <td>
                {{ this.getOverMin }}分 ({{
                  this.getHour(this.getOverMin)
                }}時間)
              </td>
            </tr>
            <tr>
              <td>不足時間</td>
              <td>{{ this.getShortMin }}分</td>
            </tr>
            <tr>
              <td>過不足計</td>
              <td>
                {{ this.getSubTotal }}分 ({{
                  this.getHour(this.getSubTotal)
                }}時間)
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <a class="btn bg-gradient-success mt-1" href="#" type="button" @click="back">もどる</a>
    <br />
    <br />
    <a class="btn bg-gradient-warning mt-1" href="#" type="button" @click="branchMonthList">全データ</a>
  </div>
</template>
<script>
//import { Magazine } from "../newsDataV2.js";
import { onStampList, onUpdateMemo } from "../Repository";

export default {
  name: "",
  //components: { NewsCardV2 },
  data() {
    return {
      render: 0,
      //      totalMin: 0,
      //      sumHour: 0,
      //      sumMin: 0,
      //      sumed: false,
    };
  },

  computed: {
    getLateMin() {
      return this.$store.state.lateMin;
    },
    getLateTime() {
      return this.$store.state.lateTime;
    },
    getOverMin() {
      return this.$store.state.overMin;
    },
    getShortMin() {
      return this.$store.state.shortMin;
    },
    getSubTotal() {
      return this.$store.state.overMin + this.$store.state.shortMin;
    },
    getSumH() {
      return this.$store.state.sumH;
    },
    getSumM() {
      return this.$store.state.sumM;
    },
    getSumMin() {
      return this.$store.state.sumMin;
    },

    date() {
      return this.$store.state.dtStamp;
    },
    user() {
      return this.$store.state.curUser;
    },
    list() {
      return this.$store.state.stamps;
    },
    days() {
      return this.$store.state.stamps.length;
    },
  },
  updated() { },
  mounted() {
    console.log("months mounted! ----------------------------------");
    onStampList();
  },
  methods: {
    start() {
      if (this.$store.state.monthObj)
        if (this.$store.state.monthObj.start)
          return this.$store.state.monthObj.start;
      if (this.$store.state.userObj) return this.$store.state.userObj.start;
      return "";
    },
    isHoliday(idx) {
      if (this.$store.state.stamps[idx].holiday)
        return "休出"
      return "　"
    },


    isMap(obj, mode) {
      var gps;
      if (mode == "in") {
        gps = obj.envIn.gps;
      } else {
        gps = obj.envOut.gps;
      }
      if (gps) {
        return true;
      }
      return false;
    },

    callMap(obj, mode) {
      var url = "https://www.google.co.jp/maps/@";
      console.log(obj.envIn);
      var gps;
      if (mode == "in") {
        gps = obj.envIn.gps;
      } else {
        gps = obj.envOut.gps;
      }
      if (gps) {
        url = url + gps[0] + "," + gps[1] + ",15z";
        window.open(url);
      }
    },

    getHour(min) {
      return Math.floor(min / 60);
    },
    getMin(min) {
      return min % 60;
    },
    getMemoId(id) {
      return "memo" + id;
    },
    updateMemo(id) {
      console.log(id);
      var val = document.getElementById("memo" + id).value;
      console.log(val);
      onUpdateMemo(parseInt(id), val);
    },
    getIP(obj) {
      if ("envIn" in obj) return obj.envIn.ip;
      return "";
    },
    getUA(obj) {
      if ("envIn" in obj) return obj.envIn.ua;
      this.render++;
      return "";
    },

    back() {
      this.$router.push("stamp");
    },
    branchMonthList() {
      this.$router.push("months");
    },
  },
};
</script>
<style>
td .input {
  width: 100%;
}

.ts-table th {
  background-color: #b6cfd7;
  padding: 0px 8px;
}

.ts-table {
  border: solid 1px #ddd;
  background-color: #d9e5ec;
}

.ts-table tr {
  border-bottom: solid 1px #eee;
}

.ts-table td {
  border-right: solid 1px #eee;
  line-height: 16px;
}

table.statistics {
  border-top: solid 2px rgb(202, 187, 187);
  border-bottom: solid 2px rgb(202, 187, 187);
}

table.statistics td {
  border-bottom: solid 1px #aaa;
  padding: 2px 10px;
}

table.statistics td {
  background-color: #eef0d1;
}
</style>
